<template>
  <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr class="theme-bg">
          <td class="text-center font-weight-bold primary--text">
            องค์ประกอบสะพาน
          </td>
          <td class="text-center font-weight-bold primary--text">
            {{ title }}
          </td>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, index) of table"
          :key="index"
          :class="{ 'theme-bg': index % 2 === 1 }"
        >
          <td class="text-center">
            <b>{{ item.name }}</b>
            <span v-if="item.warn === true" class="red--text">*</span>
            <div v-if="item.requires" class="d-flex justify-center">
              <ul style="list-style-position: inside; paddingleft: 0">
                <li v-for="requireitem of item.requires" :key="requireitem">
                  {{ requireitem }}
                </li>
              </ul>
            </div>
          </td>
          <td class="text-center">
            {{ item.score || '-' }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: 'StepZeroPrincipalTable',
  props: {
    title: String,
    table: Array
  },
  data: () => {
    return {
      showData: true
    }
  }
}
</script>

<style scoped>
.table__header {
  letter-spacing: 0.5px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr:last-child {
  border-bottom: 1px solid #c4c4c4;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  border-bottom: 1px solid white !important;
}

.v-data-table
  > .v-data-table__wrapper
  > table
  > tbody
  > tr
  > td:not(:last-child) {
  border-right: 1px solid white;
}

.expansion-open:before {
  content: '\1f846';
}

.expansion-close:before {
  content: '\1f847';
}
</style>
